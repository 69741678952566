.UsageChoice {
	display: grid;
	grid-template-columns: 80px repeat(4, 40px);
	grid-template-rows: 200px repeat(4, auto);
	transform: translateY(-10px);
	font-size: 12px;
	letter-spacing: 0.00938em;
	font-weight: 400;
}

.UsageChoice_leftItems {
	padding-top: 5px;
	line-height: 1.25em;
	white-space: pre;
}

.UsageChoice__header {
	align-self: center;
	justify-self: center;
	white-space: nowrap;
	font-size: 12px;
}

.UsageChoice__header--first {
	transform: rotate(-60deg) translateY(-5px) translateX(15px);
	align-self: end;
}

.UsageChoice__header--second {
	transform: rotate(-60deg) translateY(42px) translateX(-56px);
}

.UsageChoice__header--third {
	transform: rotate(-60deg) translateY(43px) translateX(-6px);
}

.UsageChoice__header--fourth {
	transform: rotate(-60deg) translateY(45px) translateX(6px);
}

.UsageChoice__header--fifth {
	transform: rotate(-60deg) translateY(45px) translateX(-36px);
}

.UsageChoice__inputContainer {
	width: 20px;
	padding: 5px 10px 10px;
	border-right: 1px solid rgb(184, 178, 178);
}

.UsageChoice__inputContainer--last {
	border-right: none;
}
