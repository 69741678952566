.SignupPage {
	box-sizing: border-box;
	margin: 0 auto;
	width: 100%;
	max-width: 400px;
	padding: 16px;
	color: #2d2047;
	overflow: auto;
}

.SignupPage__logo {
	width: 80px;
	display: block;
}

.SignupPage__header {
	font-family: 'Recoleta';
	font-size: 36px;
	color: #83b200;
	margin-top: 40px;
	margin-bottom: 20px;
}

.SignupPage__paragraph {
	font-weight: 400;
	line-height: 1.4;
	margin-bottom: 23px;
}

.SignupPage__form {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
}

.SignupPage__form input {
	width: 100%;
}

.SignupPage__formAcceptContainer {
	margin-top: 10px;
	display: flex;
	gap: 10px;
	justify-content: start;
}

.SignupPage__formAcceptContainer input {
	margin: 0;
	margin-top: 5px;
	cursor: pointer;
	width: 15px;
	height: 15px;
}

.SignupPage__formAcceptContainer label {
	cursor: pointer;
	user-select: none;
}

.SignupPage__buttons {
	margin-top: 12px;
	display: flex;
	gap: 20px;
}

.SignupPage__errors {
	color: red;
}

.SignupPage__link {
	text-decoration: none;
	color: #3dade8 !important;
}

.SignupPage__labelCheckbox {
	line-height: 24px;
}

.SignupPage__resetButton {
	color: #707070;
}

.SignupPage__select {
	width: 100%;
	padding: 16px 15px 16px 15px;
	border: none;
	border-radius: 4px;
	background-color: #fff;
	appearance: none;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2212%22%20viewBox%3D%220%200%2014%2012%22%3E%3Cpolygon%20points%3D%220%2C0%2014%2C0%207%2C12%20z%22%20fill%3D%22%232d2047%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat;
	background-size: 12px;
	background-position: right 12px center;
	outline: none;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.SignupPage__select option {
	outline: none;
	color: #2d2047;
	padding: 8px 12px;
}
