.ForgotPasswordPage {
	box-sizing: border-box;
	margin: 0 auto;
	width: 100%;
	max-width: 400px;
	padding: 16px;
	color: #2d2047;
	overflow: auto;
}

.ForgotPasswordPage__logo {
	width: 80px;
	display: block;
}

.ForgotPasswordPage__header {
	font-family: 'Recoleta';
	font-size: 36px;
	color: #83b200;
	margin-top: 40px;
	margin-bottom: 20px;
}

.ForgotPasswordPage__paragraph {
	font-weight: 400;
	line-height: 1.4;
	margin-bottom: 25px;
}

.ForgotPasswordPage__form {
	display: flex;
	flex-direction: column;
	gap: 26px;
	width: 100%;
}

.ForgotPasswordPage__form input {
	width: 100%;
}

.ForgotPasswordPage__formAcceptContainer {
	margin-top: 20px;
	display: flex;
	gap: 10px;
	justify-content: start;
}

.ForgotPasswordPage__formAcceptContainer input {
	width: max-content;
	margin: 0;
	margin-top: 3px;
	cursor: pointer;
}

.ForgotPasswordPage__formAcceptContainer label {
	cursor: pointer;
	user-select: none;
}

.ForgotPasswordPage__buttons {
	display: flex;
	gap: 20px;
}

.ForgotPasswordPage__errors {
	color: red;
}

.ForgotPasswordPage__success {
	color: #83b200;
}

.ForgotPasswordPage__separator {
	height: 1px;
	width: 100%;
	background-color: #e0e0e0;
	margin-top: 33px;
	margin-bottom: 25px;
}

.ForgotPasswordPage__paragraph2 {
	color: #707070;
}

.ForgotPasswordPage__paragraph3 {
	font-size: 14px;
	color: #707070;
}

.ForgotPasswordPage__link {
	text-decoration: none;
	color: #3dade8 !important;
}

.ForgotPasswordPage__resetButton {
	color: #707070;
}
