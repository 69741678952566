.Menu {
	height: calc(100vh - 82px);
	overflow: auto;
	scroll-behavior: smooth;
}

@media (min-width: 440px) {
	.Menu {
		height: 100vh;
	}
}

.Menu__content {
	margin: 0 auto;
	max-width: 400px;
	min-height: calc(100vh - 82px);
	border-radius: 3px;
	padding: 10px 20px;
}

@media (min-width: 440px) {
	.Menu__content {
		max-width: 535px;
		margin: 0;
		margin-left: 480px;
	}
}

.Menu__logo {
	display: block;
	width: 80px;
	margin: 0 auto;
	margin-bottom: 40px;
}

@media (min-width: 440px) {
	.Menu__logo {
		display: none;
	}
}

.Menu__singleItem {
	text-decoration: none;
	cursor: pointer;
	user-select: none;
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 15px 0;
}

@media (min-width: 440px) {
	.Menu__singleItem {
		display: none;
	}
}

.Menu__singleItem svg {
	width: 25px;
}

.Menu__itemText {
	color: #707070;
	font-size: 22px;
	font-weight: 400;
}

.Menu__title {
	font-family: 'Recoleta';
	font-size: 36px;
	color: #83b200;
}

.Menu__mainList {
	padding-left: 20px;
}

.Menu__mainListItem {
	color: #707070;
	font-weight: 400;
	margin-bottom: 10px;
}

.Menu__mainListItem a {
	text-decoration: none;
	color: #56b6ea;
	line-height: 1.4;
	white-space: pre-wrap;
}

.Menu__subList {
	list-style-type: disc;
}

.Menu__subListItem {
	color: #707070;
	font-weight: 400;
	margin-bottom: 10px;
}

.Menu__subListItem a {
	text-decoration: none;
	color: #56b6ea;
}

.Menu__subtitle {
	display: flex;
	gap: 10px;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 10px;
}

.Menu__subtitle h2 {
	color: #2d2047;
	font-size: 24px;
	font-weight: 600;
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.Menu__subtitle div {
	cursor: pointer;
}

.Menu__description h3 {
	color: #2d2047;
	font-size: 18px;
	line-height: 1.4;
	font-weight: 600;
	margin: 0;
}

.Menu__description p {
	color: #2d2047;
	margin: 0;
	line-height: 1.5;
	margin-bottom: 8px;
	white-space: pre-wrap;
}

.Menu__description a {
	color: #56b6ea;
	text-decoration: none;
}

.Menu__description ol {
	color: #2d2047;
	margin: 0;
	padding: 0 20px;
	line-height: 1.5;
	margin-bottom: 8px;
}

.Menu__description ol li {
	margin-bottom: 8px;
}

.Menu__description--withSubmenu p {
	margin-bottom: 10px;
}
