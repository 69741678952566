.root {
	display: grid;
	grid-template:
		'overlay overlay' 50px
		'content content' 1fr
		'layer menu' auto;
	position: absolute;
	height: 100vh;
	width: 100vw;
	z-index: 4;
	padding: 30px 16px;
	box-sizing: border-box;
	gap: 16px;
	/* Allow map interactions through the container */
	pointer-events: none;
}

.overlayMenu {
	grid-area: overlay;
	justify-self: end;
	margin-right: 68px;
	pointer-events: auto;
}

.content {
	grid-area: content;
	justify-self: start;
	display: flex;
	flex-direction: column;
	gap: 16px;
	overflow: hidden;
	width: 100%;
}

.contentContainer {
	background-color: #fff;
	border-radius: 20px;
	max-width: 438px;
	padding: 16px 0;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	pointer-events: auto;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.14);
}

.switchLayer {
	grid-area: layer;
	cursor: pointer;
	justify-self: start;
	align-content: end;
	pointer-events: auto;
}

.menu {
	grid-area: menu;
	justify-self: end;
	align-content: end;
}

.menuContainer {
	display: flex;
	align-items: center;
	gap: 16px;

	& > * {
		pointer-events: auto;
	}
}

.totalEdges {
	display: flex;
	justify-content: space-between;
	margin: 8px;
	font-size: 11px;
	color: #888;
}

.select {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	padding-right: 48px !important;
	padding-left: 24px !important;
	height: 51px !important;
	box-sizing: border-box !important;
	background: #fff !important;
	border-radius: 20px !important;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.14);

	&:focus {
		background: #fff;
		border-radius: 20px;
	}
}

.selectSubheader {
	background: #fff;
}

.selectMenuItem {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.selectIcon {
	right: 24px !important;
}

.routingContainer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin: 0 16px;
	gap: 6px;
}

.chipContainer {
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
	width: 100%;
}

.buttonContainer {
	display: flex;
	gap: 16px;
}

.routeControls {
	margin: 0 16px 16px 16px;
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	align-items: center;
}

.routeInputs {
	margin: 16px 16px 0 16px;
	display: flex;
	flex-direction: column;
}

.edgeTable {
	font-size: 12px;
	color: #444;
	width: 100%;
}

.edgeRow {
	transition: background 0.5s ease-out;
	cursor: pointer;

	& > td:not(:first-child) {
		padding: 6px 4px;
	}
	&:hover {
		background-color: #f8f7f5;
	}
}

.edgeName {
	width: 100%;
	/* Required for text-overflow */
	max-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
}

.edgeDeleteButton {
	padding: 0 !important;
	margin-right: 12px !important;
}

.edgeControls {
	display: flex;
	justify-content: space-between;
	margin: 16px 16px 0 16px;
}

/* On small displays there is a menu at the bottom */
@media (max-width: 440px) {
	.menuClosed {
		padding-bottom: 82px;
	}
}
