.ClassificationVoting__content {
	margin: 0 auto;
	width: 100%;
	max-width: 400px;
	min-height: calc(100vh - 82px);
	text-align: center;
}

@media (min-width: 440px) {
	.ClassificationVoting__content {
		max-width: none;
	}
}

.ClassificationVoting__firstPart {
	padding: 30px 15px;
}

@media (min-width: 440px) {
	.ClassificationVoting__firstPart {
		text-align: left;
		max-width: 370px;
	}
}

.ClassificationVoting__mainTitle {
	font-size: 34px;
	color: #83b200;
	font-family: 'Recoleta';
	font-weight: 400;
	line-height: 1;
	margin: 0;
	margin-bottom: 10px;
	text-align: center;
}

@media (min-width: 440px) {
	.ClassificationVoting__mainTitle {
		text-align: left;
	}
}

.ClassificationVoting__subtitle {
	margin: 0;
	font-size: 16px;
	font-weight: normal;
	color: #9ca9ad;
}

.ClassificationVoting__switcher {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	border-radius: 10px;
	margin: 30px auto 15px auto;
	width: min-content;
	padding: 2px;
	border: 1px solid #e6e6e5;
}

@media (min-width: 440px) {
	.ClassificationVoting__switcher {
		margin: 30px 0 15px 0;
	}
}

.ClassificationVoting__switcherOption {
	font-size: 16px;
	padding: 10px;
	border-radius: 8px;
	cursor: pointer;
}

.ClassificationVoting__switcherOption--active {
	color: white;
	background-color: #83b200;
}

.ClassificationVoting__options {
	font-size: 14px;
	margin-bottom: 30px;
	display: grid;
	gap: 15px;
}

.ClassificationVoting__singleOptions {
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 120px;
	background-color: white;
	padding: 10px;
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	cursor: pointer;
	transition: all 0.3s;
	user-select: none;
	text-decoration: none;
}

.ClassificationVoting__singleOptions:hover {
	transform: translateY(-2px);
}

.ClassificationVoting__singleOptions div {
	text-align: left;
}

.ClassificationVoting__optionTitle {
	margin-top: 0;
	color: #2d2047;
	margin-bottom: 5px;
}

.ClassificationVoting__optionParagraph {
	margin: 0;
	color: #9ca9ad;
}

.ClassificationVoting__singleOptions img {
	width: 100%;
}

.ClassificationVoting__order {
	font-weight: 400;
	font-size: 14px;
	color: #9ca9ad;
}

.ClassificationVoting__order a {
	text-decoration: none;
	color: #56b6ea;
}

.ClassificationVoting__votesContainer {
	border-top: 2px solid #ecebea;
	padding: 30px 15px;
}

@media (min-width: 440px) {
	.ClassificationVoting__votesContainer {
		border-top: none;
	}
}

.ClassificationVoting__votingTitle {
	margin: 0;
	margin-bottom: 20px;
	font-size: 12px;
	font-weight: bold;
	color: #aab5b8;
}

.ClassificationVoting__classificationTitle {
	margin: 0;
	margin-bottom: 10px;
	font-size: 12px;
	font-weight: bold;
	color: #2d2047;
	padding: 0 15px;
	text-align: left;
	visibility: hidden;
}

.ClassificationVoting__classificationTitle--visible {
	visibility: visible;
}

.ClassificationVoting__votes {
	display: flex;
	justify-content: space-between;
}

@media (min-width: 440px) {
	.ClassificationVoting__votes {
		margin-bottom: 25px;
		gap: 40px;
	}
}

.ClassificationVoting__votesNumber {
	color: #707070;
	font-weight: 400;
	font-size: 38px;
}

.ClassificationVoting__votesDesc {
	margin: 0;
	font-weight: 600;
	font-size: 12px;
	color: #aab5b8;
}

.ClassificationVoting__votesImages {
	display: grid;
	justify-content: space-between;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 10px;
	padding: 0 15px 30px 15px;
	visibility: hidden;
}

.ClassificationVoting__votesImages--visible {
	visibility: visible;
}

.ClassificationVoting__singleImage {
	max-width: 120px;
	height: 90px;
	position: relative;
}

.ClassificationVoting__singleImage img {
	border-radius: 5px;
	/* filter: brightness(70%); */
	opacity: 0.5;
}

.ClassificationVoting__imageMark {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ClassificationVoting__contentInner {
	position: relative;
}

@media (min-width: 440px) {
	.ClassificationVoting__contentInner {
		display: flex;
		padding-top: 140px;
		gap: 56px;
	}
}

.ClassificationVoting__help {
	cursor: pointer;
	position: absolute;
	top: 30px;
	right: 15px;
}

.ClassificationVoting__modalTitle {
	font-size: 24px;
	line-height: 1.2;
	font-weight: 400;
	margin-bottom: 20px;
}

.ClassificationVoting__modalSubtitle {
	margin-top: 0;
	margin-bottom: 5px;
}

.ClassificationVoting__modalParagraph {
	margin-top: 0;
	margin-bottom: 20px;
	line-height: 1.3;
}

.ClassificationVoting__modalLabel {
	color: #b7c1c4;
	font-size: 14px;
	font-weight: 600;
}
