.root {
	display: flex;
	position: absolute;
	left: calc(50% - 25px);
	top: 50%;
	color: #5fabe3;

	& > * + * {
		margin-left: 16px;
	}
}

.circle {
	stroke-linecap: round;
	color: #5fabe3;
}
