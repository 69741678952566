.myRoutes--wrapper {
	position: absolute;
	max-width: 470px;
	width: 100%;
	padding: 100px 16px 16px 16px;
	z-index: 4;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.myRoutes--input:focus {
	border: 1px solid #afafaf;
}
.myRoutes--input {
	box-sizing: border-box;
	width: 280px;
	height: 35px;
	padding: 12px;
	border-radius: 8px;
	outline: none;
	border: 1px solid #e1e1e1;
}
.myRoutes--searchWrapper {
	display: flex;
	align-items: center;
}
.myRoutes--searchClose {
	transition: all 0.3s;
}
.myRoutes--searchResultsTitle {
	font-size: 14px;
	margin-bottom: 12px;
	color: #a1a1a1;
}
.myRoutes--createNewRoute {
	display: flex;
	align-items: center;
	gap: 8px;
	box-sizing: border-box;
	outline: none;
	border: none;
	border-radius: 8px;
	padding: 8px;
	background-color: #afafaf;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
}
.myRoutes--createNewRoute:hover {
	background-color: #8f8f8f;
}
.myRoutes--createNewRoute:active {
	background-color: #6f6f6f;
}
.myRoutes--routeListItemWrapper {
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 4px 0;
	cursor: pointer;
	width: 100%;
}
.myRoutes--routeDetailWrapper {
	flex: 1;
}
.myRoutes--routeName {
	font-size: 14px;
	color: #333;
}
.myRoutes--routeDetail {
	font-size: 12px;
	color: #777;
}
.myRoutes--deleteRouteWrapper {
	position: absolute;
	display: flex;
	align-items: center;
	gap: 8px;
	right: 16px;
}
.myRoutes--deleteRouteWrapper button {
	background-color: #afafaf;
	border-radius: 8px;
	padding: 4px 8px;
	color: #fff;
	font-weight: 500;
	font-size: 12px;
	border: none;
	cursor: pointer;
}
.myRoutes--routeDeleteButton {
	background-color: #ff0000 !important;
}
.myRoutes--hidden {
	visibility: hidden;
}
.myRoutes--TabWrapper {
	display: flex;
	justify-content: space-evenly;
	background-color: #f5f5f5;
	color: #535353;
	font-weight: 500;
	cursor: pointer;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	border-bottom: 1px solid #f1f1f1;
}
.myRoutes--TabWrapper div {
	display: flex;
	flex: 1;
	align-items: center;
	gap: 8px;
	justify-content: center;
	align-items: center;
	padding: 16px;
	font-size: 14px;
}
.myRoutes--TabWrapper div.left {
	border-top-left-radius: 20px;
}
.myRoutes--TabWrapper div.right {
	border-top-right-radius: 20px;
}
.myRoutes--TabWrapper div:hover {
	/** hover from this 707070 */
	background-color: #f5f5f5;
}
.myRoutes--TabWrapper div.active {
	background-color: #fefefe;
	color: #111;
}
.myRoutes--contentWrapper {
	display: flex;
	gap: 16px;
	flex-direction: column;
	padding: 16px 30px;
}
.myRoutes--configItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	font-size: 14px;
	font-weight: 500;
}
.myRoutes--configSelect {
	flex: 1;
	max-width: 250px;
	min-width: 0;
}
.myRoutes--routingWrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	background-color: #f5f5f5;
	padding: 8px;
	border-radius: 8px;
	max-height: 236px;
	box-sizing: border-box;
	overflow: auto;
}
.myRoutes--button {
	background-color: #383838;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	border: none;
	border-radius: 8px;
	padding: 8px;
	cursor: pointer;
}
.myRoutes--button:hover {
	background-color: #5f5f5f;
}
.myRoutes--button:active {
	background-color: #7f7f7f;
}
.myRoutes--button:disabled {
	background-color: #e1e1e1;
	color: #fff;
}
.myRoute--saveRoutesWrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.myRoutes--waypointsWrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow-x: clip;
}
.myRoutes--waypointItemWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
}
.myRoutes--waypointItem {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	font-size: 14px;
	background-color: #fff;
	border-radius: 8px;
	border: 1px solid #e1e1e1;
	padding: 4px 8px;
	cursor: pointer;
}
.myRoutes--waypointItem:hover {
	border: 1px solid #afafaf;
}
.myRoutes--waypointTitleWrapper {
	gap: 8px;
	display: flex;
}
.myRoutes--waypointTitle {
	font-size: 14px;
	color: #444;
}
.myRoutes--waypointEnum {
	font-size: 14px;
	color: #333;
	font-weight: 600;
}
.myRoutes--waypointDeleteIcon {
	cursor: pointer;
	transition: all 0.3s;
	box-sizing: border-box;
}
.myRoutes--contentWrapper select {
	border: 1px solid #e1e1e1;
	border-radius: 8px;
	outline: none;
	padding: 4px 8px;
	font-size: 12px;
	text-overflow: ellipsis;
}
.myRoute--infoOnRouting {
	font-size: 14px;
	color: #777;
	line-height: 20px;
}
.myRoutes--iconButton {
	align-self: start;
}
.myRoutes--iconButtonLabel {
	position: absolute;
	left: 30px;
	white-space: nowrap;
	font-size: 12px;
}
