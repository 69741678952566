.MapWidthImageContainer {
    margin: 0 auto;
    width: 100%;
    max-width: 375px;
    border: 1px solid #EDECEC;
    border-radius: 3px;
}

@media (min-width: 440px) {
    .MapWidthImageContainer {
        max-width: 800px;
    }
}

.MapWidthImage {
    width: 100%;
    height: 250px;
    position: relative;
}

.MapWidthImage__map {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
}

.MapWidthImage__image {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.MapWidthImage--visible {
    z-index: 2;
}

.MapWidthImage--hidden {
    z-index: 1;
}

.MapWidthImage__desc {
    height: 30px;
    background-color: white;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.MapWidthImage__address {
    color: #2d2047;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    display: flex;
    align-items: center;
    gap: 8px;
}

.MapWidthImage__address svg {
    cursor: pointer;
}

.MapWidthImage__address input {
    padding: 4px 8px;
    border: 1px solid silver;
}

.MapWidthImage__addressCancelButton {
    background-color: #cad0d2;
    border-radius: 3px;
    padding: 4px 8px;
    min-width: 70px;
    display: flex;
    justify-content: center;
}

.MapWidthImage__addressSaveButton {
    background-color: rgb(60, 125, 56);
    color: white;
    border-radius: 3px;
    padding: 4px 8px;
    min-width: 70px;
    display: flex;
    justify-content: center;
}

.MapWidthImage__addressError {
    color: rgb(255, 86, 93);
}

.MapWidthImage__addressSuccess {
    color: rgb(60, 125, 56);
}

.MapWidthImage__city {
    color: #9CA9AD;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
}

.MapWidthImage__pin {
    cursor: pointer;
    user-select: none;
}
