.placeSearchResults--placeInnerWrapper {
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
	justify-content: center;
}
.placeSearchResults--placeWrapper:hover {
	background-color: #f5f5f5;
	border-radius: 8px;
}
.placeSearchResults--place {
	display: flex;
	flex-direction: column;
	gap: 8px;
	cursor: pointer;
	padding: 8px;
	flex: 1;
}
.placeSearchResults--placeList {
	display: flex;
	flex-direction: column;
	gap: 0;
	overflow-y: auto;
	max-height: 350px;
	scrollbar-width: thin;
}
.placeSearchResults--placeName {
	font-size: 14px;
	font-weight: 500;
}
.placeSearchResults--placeAddress {
	font-size: 12px;
	color: #666;
}
.placeSearchResults--icon {
	padding-left: 4px;
}
.placeSearchResults--placeActionsWrapper {
	display: flex;
	gap: 8px;
	margin-bottom: 8px;
	margin-top: 8px;
	justify-content: center;
}
.placeSearchResults--placeActionsWrapper input {
	border: 1px solid #e1e1e1;
	border-radius: 8px;
	outline: none;
	padding: 4px 8px;
	font-size: 12px;
}
.placeSearchResults--placeActionsWrapper select {
	outline: none;
	border: 1px solid #e1e1e1;
	border-radius: 8px;
	font-size: 12px;
	width: 55px;
}
.placeSearchResults--placeActionsWrapper button {
	outline: none;
	background-color: #efefef;
	border: none;
	border-radius: 8px;
	font-size: 12px;
	cursor: pointer;
}
.placeSearchResults--placeActionsWrapper button:hover {
	background-color: #e2e2e2;
}
.placeSearchResults--placeActionsWrapper button:active {
	background-color: #cfcfcf;
}
.placeSearchResults--MyStartLocationIcon {
	display: flex;
	justify-content: center;
	background-color: rgb(255, 255, 255);
	border: 2px solid rgb(42, 42, 42);
	border-radius: 50%;
	width: 14px;
	height: 14px;
	margin: 2px;
	box-sizing: border-box;
}
.placeSearchResults--routingActionsWrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	font-size: 14px;
}
.placeSearchResults--routingButtonsWrapper {
	display: flex;
	flex-direction: column;
	/* background-color: #efefef; */
	padding: 16px;
	border-radius: 8px;
	flex: 1;
	gap: 8px;
	justify-content: center;
}
.placeSearchResults--routingButton {
	outline: none;
	background-color: #383838;
	color: #fff;
	font-weight: bold;
	border: none;
	border-radius: 8px;
	font-size: 12px;
	cursor: pointer;
	padding: 8px;
}
.placeSearchResults--routingButton:hover {
	background-color: #5f5f5f;
}
.placeSearchResults--routingButton:active {
	background-color: #7f7f7f;
}
