.MenuIcon {
	color: #707070;
	cursor: pointer;

	& svg path {
		fill: #707070;
	}
}

.MenuIcon:hover {
	color: #5fabe3;

	& svg path {
		fill: #5fabe3;
	}
}

.MenuIcon.MenuIcon--active {
	color: #5fabe3;

	& svg path {
		fill: #5fabe3;
	}
}

.GlobalVotingMap {
	max-width: 350px;
}

.mm--drawer .GlobalVotingMap__paragraph {
	padding: 0 20px;
	text-align: center;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.4;
	color: #2d2047;
	margin-top: 0;
	margin-bottom: 20px;
	user-select: none;
}

.GlobalVotingMap__votes {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
}

.GlobalVotingMap__votes > * {
	cursor: pointer;
}

.GlobalVotingMap__voteSubmitting {
	min-height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.GlobalVotingMap__description {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 4px;
	font-weight: 600;
	padding: 0 20px;
	user-select: none;
}

.GlobalVotingMap__description--bad {
	color: #ff565d;
}

.GlobalVotingMap__description--good {
	color: #1c8131;
}

@media (min-width: 441px) {
	.mainMenu {
		display: none;
	}

	.mm--drawer {
		display: none;
	}
}
