.placeSearchResults--placeEditorHeader {
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	flex: 1;
}
.placeSearchResults--placeEditorHeaderTitle {
	font-size: 14px;
	font-weight: 600;
}
.placeSearchResults--placeEditorHeaderDetails {
	display: flex;
	align-items: center;
	font-size: 12px;
	color: #777;
}
.placeSearchResults--placeHeaderWrapper {
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: space-between;
	align-items: center;
}
.placeSearchResults--placeEditorHeaderClose {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 4px;
}
.placeSearchResults--placeBorder {
	flex: 1;
	height: 1px;
	background-color: #f1f1f1;
}
.placeSearchResults--placeEditorHeaderCloseWrapper {
	display: flex;
	align-items: center;
	gap: 16px;
}
.placeEditor--editingActionsWrapper {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	font-size: 14px;
	color: #333;
	padding: 8px;
	background-color: #f1f1f1;
	border-radius: 8px;
}
.placeEditor--savePlaceInfo {
	display: flex;
	align-items: center;
	padding: 4px;
}
