.selector-container {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 64px;
	top: 16px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 4;
	background-color: white;
	box-sizing: border-box;
	border-radius: 20px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
	padding: 8px 16px;
	transition: border-radius 0.2s ease;
	border-radius: 16px;
}

.selector-container.is-open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.selector-button {
	padding: 10px 15px;
	font-size: 16px;
	border: none;
	background-color: white;
	cursor: pointer;
	border-radius: 16px;
	display: flex;
	align-items: center;
	transition: background-color 0.2s ease;
	text-transform: capitalize;
}

.selector-button::after {
	content: '▼';
	margin-left: 10px;
	font-size: 12px;
	transition: transform 0.2s ease;
}

.selector-button.is-open::after {
	transform: rotate(180deg);
}

.dropdown-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	width: 100%;
	background-color: white;
	border-radius: 0 0 20px 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	max-height: 300px;
	overflow-y: auto;
	display: none;
}

.selector-container:hover .dropdown-list {
	display: block;
}

.dropdown-item {
	padding: 16px;
	cursor: pointer;
	transition: background-color 0.2s ease;
	text-transform: capitalize;
}

.dropdown-item:hover {
	background-color: #f5f5f5;
}
